.item_file_grid_knowledge_base {
  &__dialog {
    position: absolute;
    z-index: 2;
    top: 0;
    background: #fff;
    padding: 0;
    width: 1200px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }

  &__video {
    width: 100%;
  }

  &__container {
    display: flex;
    position: relative;
    background-color: #fff;
  }

  &__name {
    width: 300px;
    height: 150px;
    background-color: #9297aa; /* Цвет фона */
    color: white;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    clip-path: polygon(0 0, 85% 0, 100% 30%, 100% 100%, 0% 100%);
    border: 2px solid #5d7d9d; /* Граница */

    cursor: pointer;
    &:hover {
      background-color: #011248; /* Цвет фона */
    }
  }
  &__delete-file {
    position: absolute;
    top: -6px;
    right: -6px;
    cursor: pointer;
  }
}
