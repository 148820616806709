.drag-wrapper {
  position: relative;
  z-index: 99999999;
}

.drag-wrapper__tooltip {
  position: absolute;
  top: -22px;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 9999;


& .icon-drag__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -6px;
    border: solid 2px rgba(0, 0, 0, 0.3);
    border-radius: .3rem;
    font-size: 12px;
    cursor: grab;
    color: rgba(0, 0, 0, 0.87);
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.2s;
    background: rgba(255, 255, 255, 0.73);

  .drag-wrapper:hover & {
      opacity: 1;
      transform: translateY(0);
    }

  }

& .show-icon-drag {
    display: block;
  }
}

.icon-drag {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

