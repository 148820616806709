.auto-faq-service__wrapper {
  position: fixed;
  user-select: none;
  z-index: 999999;
  margin-top: 20px;
}

#chat21-launcher-button {
  position: initial !important;
}
